import { render, staticRenderFns } from "./lactationYieldOfIndividualCow.vue?vue&type=template&id=5318c742&scoped=true&"
import script from "./lactationYieldOfIndividualCow.vue?vue&type=script&lang=js&"
export * from "./lactationYieldOfIndividualCow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5318c742",
  null
  
)

export default component.exports
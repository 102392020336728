import { render, staticRenderFns } from "./milkYieldOfIndividualCow.vue?vue&type=template&id=ba8f8064&scoped=true&"
import script from "./milkYieldOfIndividualCow.vue?vue&type=script&lang=js&"
export * from "./milkYieldOfIndividualCow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba8f8064",
  null
  
)

export default component.exports